@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Cambay&display=swap");

body::-webkit-scrollbar {
  width: 7px;
  height: 6px;
}

body::-moz-scrollbar {
  width: 7px;
  height: 6px;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background-color: rgba(199, 167, 111, 0.726);
  border-radius: 9px;
}
body::-moz-scrollbar-thumb {
  background-color: rgba(199, 167, 111, 0.726);
  border-radius: 9px;
}
