.MuiMenu-list {
  color: #151516;
}

.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  box-shadow: 0px 0px 0px 0px rgba(42, 41, 41, 0.02),
    0px 0px 3px 0px rgba(36, 35, 35, 0.14),
    0px 0px 0px 0px rgba(36, 35, 35, 0.12);
  border-radius: 15px;
}

.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  /* box-shadow: 0px 0px 0px 0px rgba(58, 58, 58, 0.02), 0px 0px 3px 0px rgba(56, 55, 55, 0.14), 0px 0px 0px 0px rgba(59, 58, 58, 0.12); */
  border-radius: 15px;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
}

.css-oapmtd {
  border-radius: 15px;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
}

.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-oapmtd {
  border-radius: 15px;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
}
