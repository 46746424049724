.background-Img {
  background-size: cover;
  background-repeat: space;
  background-attachment: fixed;
  background-position: center;
}

@media all and (max-width: 1080px) {
  .background-Img {
    background-size: cover;
    background-position: center;
    background-repeat: space;
    background-attachment: fixed;
  }
}

@media all and (max-height: 830px) {
  .background-Img {
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }
}
